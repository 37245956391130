export default {
    apiUrl: "api.ias.poligrand.pl",
    mercureUrl: window.location.host,
    functionTreeApiUrl: "/files/function-tree.json",
    zpcRelations: "/api/zpc/related-activities",
    loginApiUrl: "/api/user/login",
    tokenRefreshApiUrl: "/api/token/refresh",
    userBuildingsApiUrl: "/api/user/buildings",
    testBuildingApiUrl: "/api/user/buildings/assigned",
    filesApiUrl: "/files",
    usersApiUrl: "/api/users",
    dashboardsApiUrl: "/api/dashboards",
    dashboardElementsApiUrl: "/api/dashboard_elements",
    addUserApiUrl: "/api/user/add",
    editUserApiUrl: "/api/user/edit",
    changePassApiUrl: "/api/user/password",
    selfEditApiUrl: "/api/user/self_edit",
    registerValuesApiUrl: "/api/register_values",
    registersApiUrl: "/api/registers",
    buildingsApiUrl: "/api/buildings",
    spacesApiUrl: "/api/spaces",
    rentalsApiUrl: "/api/rentals",
    devicesApiUrl: "/api/devices",
    middlewaresApiUrl: "/api/middleware",
    unitsApiUrl: "/api/units",
    peopleApiUrl: "/api/people",
    contractorsApiUrl: "/api/contractors",
    contractorContactsApiUrl: "/api/contractor_contacts",
    swotElementsApiUrl: "/api/swot_elements",
    swotCorrelationsApiUrl: "/api/swot_elements_correlations",
    swotApiUrl: "/api/swot_analyses",
    fmeasApiUrl: "/api/fmea_analyses",
    fmeaElementsApiUrl: "/api/fmea_elements",
    keizensApiUrl: "/api/improvements",
    ratingsApiUrl: "/api/rating_category_groups",
    ratesApiUrl: "/api/rate/add",
    qfdParametersApiUrl: "/api/qfd_parameters",
    qfdAnalysesApiUrl: "/api/qfd_analyses",
    qfdParametersCorrelationsApiUrl: "/api/qfd_parameter_correlations",
    qfdAddCorrelationsApiUrl: "/api/qfd_parameter_correlations/add",
    qfdPropertiesApiUrl: "/api/qfd_properties",
    qfdPropertyParametersApiUrl: "/api/qfd_property_parameters",
    qfdAnalysisPropertiesApiUrl: "/api/qfd_analysis_properties",
    benefitsApiUrl: "/api/benefits",
    goalsApiUrl: "/api/goals",
    goalBenefitsApiUrl: "/api/goal_benefits",
    zpcActivitiesApiUrl: "/api/zpc_activities",
    iasPhasesApiUrl: "/api/ias_phases",
    industriesApiUrl: "/api/industries",
    eventTypesApiUrl: '/api/event_types',
    eventsApiUrl: '/api/events',
    budgetsApiUrl: '/api/budgets',
    budgetTemplatesApiUrl: '/api/budget_templates',
    budgetCategoriesApiUrl: '/api/budget_categories',
    budgetMainCategoriesApiUrl: '/api/budget_main_categories',
    budgetTemplateCategoriesApiUrl: '/api/budget_template_categories',
    budgetTemplateMainCategoriesApiUrl: '/api/budget_template_main_categories',
    budgetPositionsApiUrl: '/api/budget_positions',
    invoicesApiUrl: '/api/invoices',
    logsApiUrl: '/api/logs',
    peopleReportsApiUrl: "/api/person_ratings",
    iasResourcesApiUrl: "/api/ias_resources",
    importDevicesApi: "/api/import_devices"
};