<template>
    <admin>
        <div class="uk-conteiner-fluid uk-padding">
          <goal-preview-modal ref="modal"/>
            <div uk-grid>
                <div class="uk-width-1-2@m">
                    <h3>Moje zadania</h3>
                    <div class="uk-card uk-card-default uk-card-body" v-if="goals.data.length>0">
                        <b-table
                                :fields="goals.fields"
                                :items="goals.data"
                                hover
                                ref="table">
                            <template v-slot:cell(goTo)="goal">
                                <a
                                        @click.prevent="goToGoal(goal.item)"
                                        class="uk-icon-button"
                                        href="#"
                                        uk-tooltip="Pokaż cel"
                                >
                                    <vk-icon class="hover" icon="arrow-right"/>
                                </a>
                            </template>
                            <template v-slot:cell(state)="goal">
                                <span 
                                v-if="getState(goal.item)" 
                                :class="{
                                    'uk-label': true, 
                                    'uk-label-success': getState(goal.item) === 'akceptowalny', 
                                    'uk-label-warning': getState(goal.item) === 'oczekiwany'
                                }"
                                >
                                {{ getState(goal.item) }}
                                </span>
                            </template>
                        </b-table>
                    </div>
                    <div class="uk-width-1-2@m" v-else>
                        <div class="alert" uk-alert>Brak zadań</div>
                    </div>
                </div>
                <div class="uk-width-1-2@m">
                    <h3>Moje analizy</h3>
                    <div class="uk-card uk-card-default uk-card-body"
                        v-if="fmeas.data.length>0 || swots.data.length>0 || qfds.data.length>0">
                        <ul uk-accordion>
                            <li class="uk-open" v-if="swots.data.length>0">
                                <a class="uk-accordion-title" href="#">Analizy SWOT</a>
                                <div class="uk-accordion-content">
                                    <b-table
                                            :fields="swots.fields"
                                            :items="swots.data"
                                            hover
                                            ref="table">
                                        <template v-slot:cell(goTo)="swot">
                                            <a
                                                    @click.prevent="goToSwot(swot.item)"
                                                    class="uk-icon-button"
                                                    href="#"
                                                    uk-tooltip="Przejdź do analizy SWOT"
                                            >
                                                <vk-icon class="hover" icon="arrow-right"/>
                                            </a>
                                        </template>
                                    </b-table>
                                </div>
                            </li>
                            <li v-if="fmeas.data.length>0">
                                <a class="uk-accordion-title" href="#">Analizy FMEA</a>
                                <div class="uk-accordion-content">
                                    <b-table
                                            :fields="fmeas.fields"
                                            :items="fmeas.data"
                                            hover
                                            ref="table">
                                        <template v-slot:cell(goTo)="fmea">
                                            <a
                                                    @click.prevent="goToFmea(fmea.item)"
                                                    class="uk-icon-button"
                                                    href="#"
                                                    uk-tooltip="Przejdź do analizy FMEA"
                                            >
                                                <vk-icon class="hover" icon="arrow-right"/>
                                            </a>
                                        </template>
                                    </b-table>
                                </div>
                            </li>
                            <li v-if="qfds.data.length>0">
                                <a class="uk-accordion-title" href="#">Analizy QFD</a>
                                <div class="uk-accordion-content">
                                    <b-table
                                            :fields="qfds.fields"
                                            :items="qfds.data"
                                            hover
                                            ref="table">
                                        <template v-slot:cell(goTo)="qfd">
                                            <a
                                                    @click.prevent="goToQfd(qfd.item)"
                                                    class="uk-icon-button"
                                                    href="#"
                                                    uk-tooltip="Przejdź do analizy QFD"
                                            >
                                                <vk-icon class="hover" icon="arrow-right"/>
                                            </a>
                                        </template>
                                    </b-table>
                                </div>
                            </li>

                        </ul>

                    </div>
                    <div class="uk-width-1-2@m" v-else>
                        <div class="alert" uk-alert>Brak analiz</div>
                    </div>
                </div>
            </div>
        </div>
    </admin>
</template>

<script>
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import mixin from "../../components/mixin";
    import levels from '../../constants/goalRealizationLevels'
    import UIkit from "uikit";
    import GoalPreviewModal from "../../components/zpc/previewModal";
    
    export default {
        name: "dashboard",
      components: {GoalPreviewModal},
      data() {
            return {
                LEVELS: levels.LEVELS,
                goals: {
                    data: [],
                    fields: [
                        {key: 'name', label: 'Nazwa'},
                        {key: 'state', label: 'Stan'},
                        {key: 'goTo', label: '', class: 'text-right'},
                    ]
                },
                swots: {
                    data: [],
                    fields: [
                        {key: 'description', label: 'Nazwa'},
                        {key: 'goTo', label: '', class: 'text-right'},
                    ]
                },
                fmeas: {
                    data: [],
                    fields: [
                        {key: 'description', label: 'Nazwa'},
                        {key: 'goTo', label: '', class: 'text-right'},
                    ]
                },
                qfds: {
                    data: [],
                    fields: [
                        {key: 'description', label: 'Nazwa'},
                        {key: 'goTo', label: '', class: 'text-right'},
                    ]
                }
            };
        },
        mixins: [mixin],
        created() {
            let vm = this
            if(localStorage.getItem('person')!=="null") {
                axiosInstance.get(apiConfig.peopleApiUrl + "/" + localStorage.getItem('person'),
                    {
                        headers: this.$data.headers
                    }).then(function (res) {
                    vm.goals.data = res.data.goals;
                    vm.swots.data = res.data.user.swotAnalyses;
                    vm.fmeas.data = res.data.user.fmeaAnalyses;
                    vm.qfds.data = res.data.user.qfdAnalyses;
                });
            }
        },
        methods: {
            goToGoal: function (goal) {
                this.$refs.modal.showModal(goal)
            },
            goToSwot: function (swot) {
                this.$router.push({
                    name: 'swotElements',
                    params: {'buildingId': swot.buildings.id, 'swotId': swot.id}
                })
            },
            goToFmea: function (fmea) {
                this.$router.push({
                    name: 'fmeaAnalysis',
                    params: {'buildingId': fmea.building.id, 'analysisId': fmea.id}
                })
            },
            goToQfd: function (qfd) {
                this.$router.push({
                    name: 'qfdAnalysis',
                    params: {'buildingId': qfd.project.id, 'analysisId': qfd.id, 'step': 1}
                })
            },
            getState: function (goal) {
                if (goal.parent !== null && (goal.realizationLevel === null || goal.realizationLevel === 0)) {
                    return "nieoceniony";
                } else {
                    let rate = "";
                    this.LEVELS.forEach(function (level) {
                        if (level.id === goal.realizationLevel) {
                            rate = level.value;
                        }
                    });
                    return rate;
                }
            }
        },
        mounted: function(){
            let vm = this;
            if(this.$route.path==="/not-allowed"){
                UIkit.modal.alert('Nie masz uprawnień do korzystania z wybranej funkcji').then(function() {
                    vm.$router.push({name: "dashboard"});
                });
            }
        }
    };
</script>

<style scoped>
    .uk-accordion li {
        list-style-type: none;
    }

    .uk-accordion-title {
        color: black;
        font-size: 1.3em;
    }

    .uk-accordion {
        padding-left: 0;
    }
</style>
