<template>
  <div class="card">
    <div class="card-title text-center">
    </div>
    <div class="card-body">
      <div class="row" v-if="$props.element.register.length===0">
        <div class="col-5">
          <div class="icon-big text-center">
            <div class="icon-danger"><i class="nc-icon nc-simple-remove text-danger uk-text-bold"></i></div>
          </div>
        </div>
        <div class="col-7">
          <div class="numbers">
            <div><p class="card-category">Nie wybrano rejestrów</p> <h4 class="card-title"></h4></div>
          </div>
        </div>
      </div>
      <div class="row" v-else-if="show===false">
        <div class="col-5">
          <div class="icon-big text-center">
            <div class="icon-danger"><i class="nc-icon nc-simple-remove text-danger uk-text-bold"></i></div>
          </div>
        </div>
        <div class="col-7">
          <div class="numbers">
            <div><p class="card-category">Brak danych</p> <h4 class="card-title"></h4></div>
          </div>
        </div>
      </div>
      <div class="mb-4" v-else>

        <chartist
            ratio="ct-double-octave"
            type="Line"
            ref="chart"
            :data="chartData"
            :options="chartOptions">
        </chartist>


      </div>
      <b-row v-if="showButtons" class="buttons-row mt-5">
        <b-col cols="12" md="12">
          <div class="date-ranges">
            <b-button :pressed="range===1" variant="outline-danger" class="w-100" @click.prevent="setRange(1,5)">1d
            </b-button>
            <b-button :pressed="range===7" variant="outline-danger" class="w-100" @click.prevent="setRange(7,30)">7d
            </b-button>
            <b-button :pressed="range===30" variant="outline-danger" class="w-100" @click.prevent="setRange(30,120)">30d
            </b-button>
            <b-button :pressed="range===90" variant="outline-danger" class="w-100" @click.prevent="setRange(90, 240)">3m
            </b-button>
            <b-button :pressed="range===180" variant="outline-danger" class="w-100" @click.prevent="setRange(180, 480)">6m
            </b-button>
            <b-button :pressed="range===365" variant="outline-danger" class="w-100" @click.prevent="setRange(365, 1440 )">
              rok
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="showButtons" class="buttons-row mt-1">
        <b-col>
          <datepicker type="date" name="dueDate" v-model="startDate"
                      data-vv-as="Od"
                      :language="pl" :input-class="'uk-input'" :format="'dd.MM.yyyy'"
                      v-validate="'required'" placeholder="Od..." @input="changeDates"/>
        </b-col>
        <b-col>
          <datepicker type="date" name="dueDate" v-model="endDate"
                      data-vv-as="Do"
                      :language="pl" :input-class="'uk-input'" :format="'dd.MM.yyyy'"
                      v-validate="'required'" placeholder="Do..." @input="changeDates"/>
        </b-col>
      </b-row>
      <b-row v-if="showButtons" class="buttons-row mt-1">
        <b-col>
          <v-select name="interval"
                    data-vv-as="Interwał" :options="intervals"
                    :class="'wpip-select'" v-model="interval" :reduce="(item)=>item.value" @input="refresh">

            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.name }}
              </div>
            </template>

            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.name }}
              </div>
            </template>

            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                Nie znaleziono <em>{{ search }}</em>.
              </template>
              <em style="opacity: 0.5;" v-else>Wyszukaj interwał</em>
            </template>
          </v-select>
        </b-col>
        <b-col>
          <v-select name="aggregationFunction"
                    data-vv-as="Funkcja agregacji" :options="functions"
                    :class="'wpip-select'" v-model="aggregationFunction" :reduce="(item)=>item.value" @input="refresh">

            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.name }}
              </div>
            </template>

            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.name }}
              </div>
            </template>

            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                Nie znaleziono <em>{{ search }}</em>.
              </template>
              <em style="opacity: 0.5;" v-else>Wyszukaj funkcję</em>
            </template>
          </v-select>
        </b-col>
      </b-row>
    </div>
    <div class="card-footer text-center" @click="toggleShowButtons()">
      <div class="text-center">
        <div class="icon-danger">
          <i v-if="!showButtons" class="nc-icon nc-stre-down text-danger uk-text-bold"></i>
          <i v-else class="nc-icon nc-stre-up text-danger uk-text-bold"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import mixin from "../mixin";
import charLimiter from "../charLimiter";
import legend from 'chartist-plugin-legend';
import tooltips from 'chartist-plugin-tooltips-updated';
import '../../assets/scss/chart-legend.scss'
import axiosInstance from "@/axiosInstance";
import {pl} from "vuejs-datepicker/src/locale";
import Datepicker from "vuejs-datepicker";

export default {
  name: 'dashboardElementLineChart',
  components: {
    datepicker: Datepicker,
  },
  data() {
    return {
      pl: pl,
      show: true,
      showButtons: false,
      chartData: {
        series: []
      },
      range: null,
      interval: null,
      startDate: null,
      endDate: null,
      aggregationFunction: 'avg',
      intervals: [
        {
          value: 1,
          name: "1 minuta",
          label: "1 minuta"
        },
        {
          value: 5,
          name: "5 minut",
          label: "5 minut"
        },
        {
          value: 10,
          name: "10 minut",
          label: "10 minut"
        },
        {
          value: 15,
          name: "15 minut",
          label: "15 minut"
        },
        {
          value: 30,
          name: "30 minut",
          label: "30 minut"
        },
        {
          value: 60,
          name: "60 minut",
          label: "60 minut"
        }
      ],
      functions: [
        {
          value: 'min',
          name: "Wartość minimalna",
          label: "Wartość minimalna",
        },
        {
          value: 'max',
          name: "Wartość maksymalna",
          label: "Wartość maksymalna"
        },
        {
          value: 'avg',
          name: "Wartość średnia",
          label: "Wartość średnia"
        }
      ],
      chartOptions: {
        lineSmooth: true,
        fullWidth: true,
        chartPadding: {
          top: 40,
          right: 50,
          left:50
        },
        plugins: [
          legend(),
          tooltips({
            appendToBody: false,
            anchorToPoint: false,
            tooltipFnc(data, value) {
              data = JSON.parse(data.replace(/&quot;/g, '"'))
              let unit = data.unitOfMeasure ? data.unitOfMeasure : ""
              return data.name + "<br>" + new Date(parseInt(value.split(',')[0])).toLocaleString() + "<br>" + parseFloat(value.split(',')[1]).toFixed(2) + " " + unit
            }
          })
        ],

        axisX: {
          type: this.$chartist.FixedScaleAxis,
          divisor: 4,
          labelInterpolationFnc: function (value) {
            return value.toLocaleDateString() + "\n " + value.toLocaleTimeString();
          }
        }
      }
    }
  },
  props: {
    element: {},
  },
  mixins: [mixin, charLimiter],
  methods: {
    generateAvailableIntervals(days){
      let vm = this

      let intervalsArray = []
      if(days <= 6){
        intervalsArray = [1, 5, 15, 30, 60]
      }
      else if(days <= 29){
        intervalsArray = [5, 15, 30, 60, 120]
      }
      else if(days <= 89){
        intervalsArray = [30, 60, 120, 240, 720]
      }
      else if(days <= 179){
        intervalsArray = [60, 120, 240, 720, 1440]
      }
      else if(days <= 179){
        intervalsArray = [240, 480, 720, 960, 1200, 1440]
      }
      else{
        intervalsArray = [720, 960, 1200, 1440]
      }

      this.intervals = []

      if(vm.interval < intervalsArray[0]){
        vm.interval = intervalsArray[0]
      }

      if(vm.interval > intervalsArray[intervalsArray.length-1]){
        vm.interval = intervalsArray[intervalsArray.length-1]
      }

      intervalsArray.forEach(value=>{
        let label = ""
        if(value < 60){
          label = value + " min."
        }
        else if(value < 1440){
          label = (value/60) + " godz."
        }
        else {
          label = "1 dzień"
        }

        vm.intervals.push(
            {
              value: value,
              name: label,
              label: label
            }
        )

      })
    },
    toggleShowButtons() {
      this.showButtons = !this.showButtons
    },
    setRange(range, interval) {
      this.interval = interval
      this.range = range
      this.startDate = null
      this.endDate = null
      this.generateAvailableIntervals(range)
      this.refresh()
    },
    changeDates(){
      let end = this.endDate
      if(end === null){
        end = new Date()
      }
      if(Math.ceil((new Date(end).getTime() - new Date(this.startDate).getTime())/(24*3600*1000))>365){
        this.startDate = new Date(new Date(end).getTime()-365*24*3600*1000)
      }
      this.generateAvailableIntervals( Math.ceil((new Date(end).getTime() - new Date(this.startDate).getTime())/(24*3600*1000)))
      this.range = null
      this.refresh()
    },
    refresh: async function () {
      this.show = true;
      let series = [];
      let vm = this;
      let params = {}
      if (vm.interval) {
        params.interval = vm.interval
      }
      if (vm.range) {
        params['days-ago'] = vm.range
      }
      if(vm.aggregationFunction){
        params['mode'] = vm.aggregationFunction
      }
      if(vm.startDate){
        params['startDate'] = new Date(vm.startDate).toISOString().split('T')[0]
      }
      if(vm.endDate){
        params['endDate'] = new Date(vm.endDate).toISOString().split('T')[0]
      }
      let values = []
      axiosInstance.get('/api/dashboard_elements/' + this.$props.element.id + '/chart_data', {
        params,
        headers: this.$data.headers
      }).then(response => {
        response.data.forEach(function (item) {
          if (!values[item.register]) {
            values[item.register] = []
          }
          values[item.register].push({date: item.date, value: item.value})
        })

        let maxElements = 0
        values.forEach(function (registerVals) {
          for (let i = registerVals.length - 1; i > 0; i--) {
            if (registerVals[i].value !== null) {
              if (maxElements < i) {
                maxElements = i
              }
              break
            }
          }
        })

        values.forEach(function (registerVals) {
          registerVals.splice(maxElements + 1)
        })

        vm.$props.element.register.forEach(function (register) {
          let reg = {...register}
          reg.chartData = []
          reg.chartData = values[register.id];
          if (reg.chartData) {
            let registerData = {
              name: vm.limit(register, 15),
              meta: JSON.stringify(register),
              data: reg.chartData.map((prop) => {
                return {
                  x: new Date(prop["date"]),
                  y: prop["value"],
                };
              })
            }
            series.push(registerData);
          }
        })
        this.chartData.series = series;
        this.calculateDates();
      })


    },
    calculateDates: function () {
      let minDate = null;
      let maxDate = null;
      this.chartData.series.forEach(function (data) {
        data.data.forEach(function (value) {
          if (minDate == null || minDate > value.x) {
            minDate = value.x;
          }
          if (maxDate == null || maxDate < value.x) {
            maxDate = value.x;
          }
        })
      });
      let ticks = []
      if (minDate !== null && maxDate !== null) {
        for (let i = 0; i <= 4; i++) {
          ticks.push(new Date(minDate.getTime() + i * (maxDate - minDate) / 4))
        }
      } else {
        this.show = false
      }
      this.chartOptions.axisX.ticks = ticks;
    }
  },
  mounted: function () {
    this.range = this.$props.element.days
    this.interval = this.$props.element.interval
    this.generateAvailableIntervals(this.range)
    this.aggregationFunction = this.$props.element.method
    this.refresh();
  }
}
</script>
<style scoped>
.icon-big {
  font-size: 3em;
}
</style>
<style lang="scss">
.ct-chart-line {
  overflow: inherit !important;

}

.ct-major-second {
  max-height: 34vh;
  height: 34vh;
}

svg.ct-chart-bar, svg.ct-chart-line {
  overflow: visible;
}

.ct-label.ct-horizontal.ct-end {
  width: 10px;
  font-size: 0.8rem!important;
  position: relative !important;
  font-weight: 600!important;
  justify-content: flex-end !important;
  text-align: right !important;
  transform-origin: 100% 0 !important;
  transform: translate(-100%) rotate(-45deg) !important;
  white-space: nowrap !important;
}

.ct-label.ct-vertical.ct-start {
  font-size: 0.8rem!important;
  font-weight: 600!important;
  color:#000;
}

.chartist-tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  display: none;
  padding: 0px 10px;
  border-radius: 5px;
  z-index: 1500;
}

.tooltip-show {
  display: block;
}

.ct-point {
  stroke-width: 5px;
}

.ct-line {
  stroke-width: 1px;
}

.buttons-row {
  z-index: 1000
}
</style>
