import { render, staticRenderFns } from "./budgetTemplateCategoriesActions.vue?vue&type=template&id=3fba3ae4"
import script from "./budgetTemplateCategoriesActions.vue?vue&type=script&lang=js"
export * from "./budgetTemplateCategoriesActions.vue?vue&type=script&lang=js"
import style0 from "./budgetTemplateCategoriesActions.vue?vue&type=style&index=0&id=3fba3ae4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports